import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Game from '../pages/Game';
import Configuration from '../pages/Configuration';
import Feedback from '../components/App';
import Ranking from '../pages/Ranking';
import PrivateRoute from '../components/PrivateRoute'; // Importe o componente PrivateRoute
import App2 from '../components/App2';


class Routes extends React.Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" component={Home} />
                <PrivateRoute path="/game" component={Game} />
                <PrivateRoute path="/configuration" component={Configuration} />
                <PrivateRoute path="/feedback" component={Feedback} />
                <PrivateRoute path="/pix" component={App2} />
                <PrivateRoute path="/ranking" component={Ranking} />
                {/* Adicione uma rota para a página de login */}
                <Route path="/login" component={Home} />
            </Switch>
        );
    }
}

export default Routes;
