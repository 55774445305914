import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Este é um exemplo simples de autenticação. Você pode substituir pelo método que estiver usando.
const isAuthenticated = () => {
    return !!localStorage.getItem('usuario'); // Verifica se há um usuário armazenado no localStorage
};

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to="/login" /> // Redireciona para a página de login se não estiver autenticado
            )
        }
    />
);

export default PrivateRoute;
