import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import style from './PlayerHeader.module.css';

const PlayerHeader = ({ score }) => {
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem('usuario'); // Remove o usuário do localStorage
    history.push('/login'); // Redireciona para a página de login
  };

  return (
      <div className={style.header}>
        <button className={style.logoutButton} onClick={handleLogout}>
          Deslogar
        </button>
        <div className={style.userInfo}>
          <p className={style.headerScore} data-testid="header-score">
            R$ {score}
          </p>
        </div>
      </div>
  );
};

const mapStateToProps = ({ gameReducer: { score } }) => ({
  score,
});

export default connect(mapStateToProps)(PlayerHeader);

PlayerHeader.propTypes = {
  score: PropTypes.number.isRequired,
};
