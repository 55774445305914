import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';
import ToastComponent from './Toast'; // Importa o componente Toast

const FormContainer = styled.div`
    background: rgba(0, 0, 0, 0.6);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    font-family: 'Arial', sans-serif;

    @media (max-width: 768px) {
        padding: 30px;
        max-width: 90%;
    }

    @media (max-width: 480px) {
        padding: 20px;
        max-width: 95%;
    }
`;

const Header = styled.h1`
    font-size: 2.5rem;
    color: #00E5A3;
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgb(232, 199, 87);

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 15px;
    }

    @media (max-width: 480px) {
        font-size: 1.5rem;
        margin-bottom: 10px;
    }
`;

const ToggleSwitch = styled.button`
    margin-bottom: 20px;
    padding: 12px 24px;
    background-color: #34c79d;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgb(232, 199, 87);
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: rgba(1, 89, 129, 0.6);
        transform: scale(1.05);
    }

    &:active {
        background-color: #ffb700;
        transform: scale(0.98);
    }

    @media (max-width: 768px) {
        padding: 10px 20px;
        font-size: 1rem;
    }

    @media (max-width: 480px) {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
`;

const Input = styled.input`
    width: 90%;
    padding: 15px;
    margin-bottom: 15px;
    border: 2px solid #007bff;
    border-radius: 30px;
    font-size: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    transition: border-color 0.3s, box-shadow 0.2s;

    &:focus {
        border-color: #0056b3;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        outline: none;
    }

    @media (max-width: 768px) {
        padding: 12px;
        font-size: 0.9rem;
    }

    @media (max-width: 480px) {
        padding: 10px;
        font-size: 0.8rem;
    }
`;

const Button = styled.button`
    padding: 15px 30px;
    background-color: #34c79d;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 20px;
    width: 100%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
        background-color: rgba(1, 89, 129, 0.6);
        transform: scale(1.05);
    }

    &:active {
        background-color: #004494;
        transform: scale(0.98);
    }

    @media (max-width: 768px) {
        padding: 12px 24px;
        font-size: 1rem;
    }

    @media (max-width: 480px) {
        padding: 10px 20px;
        font-size: 0.9rem;
    }
`;
const LoginRegister = ({ onLoginSuccess }) => {
    const [isLogin, setIsLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const history = useHistory();

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setUsername('');
        setPassword('');
        setEmail('');
        setPhone('');
    };

    const showToastMessage = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    };

    const validateFields = () => {
        let isValid = true;
        let errorMessage = '';

        if (!username) {
            errorMessage = 'Usuário é obrigatório.';
            showToastMessage(errorMessage);
            isValid = false;
        } else if (!password) {
            errorMessage = 'Senha é obrigatória.';
            showToastMessage(errorMessage);
            isValid = false;
        } else if (!isLogin) {
            if (!email) {
                errorMessage = 'E-mail é obrigatório.';
                showToastMessage(errorMessage);
                isValid = false;
            } else if (!phone) {
                errorMessage = 'Número de telefone é obrigatório.';
                showToastMessage(errorMessage);
                isValid = false;
            }
        }

        return isValid;
    };

    const handleRegister = () => {
        if (validateFields()) {
            const user = { username, password, email, phone };
            localStorage.setItem('usuario', JSON.stringify(user));
            showToastMessage('Registrado com sucesso! Faça login.');
            toggleForm();
        }
    };

    const handleLogin = () => {
        if (validateFields()) {
            const storedUser = JSON.parse(localStorage.getItem('usuario'));
            if (storedUser && storedUser.username === username && storedUser.password === password) {
                showToastMessage('Login bem-sucedido!');
                if (typeof onLoginSuccess === 'function') {
                    onLoginSuccess();
                } else {
                    console.error('onLoginSuccess não é uma função');
                }
                history.push('/');
            } else {
                showToastMessage('Nome de usuário ou senha incorretos.');
            }
        }
    };

    return (
        <>
            <FormContainer>
                <Header>{isLogin ? 'Login' : 'Registrar'}</Header>
                <ToggleSwitch onClick={toggleForm}>
                    {isLogin ? 'Registrar' : 'Login'}
                </ToggleSwitch>
                <Input
                    type="text"
                    placeholder="Usuário"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                {!isLogin && (
                    <>
                        <Input
                            type="email"
                            placeholder="E-mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <InputMask
                            mask="(99) 99999-9999"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        >
                            {(inputProps) => (
                                <Input
                                    {...inputProps}
                                    placeholder="Número de telefone"
                                />
                            )}
                        </InputMask>
                    </>
                )}
                <Button onClick={isLogin ? handleLogin : handleRegister}>
                    {isLogin ? 'Login' : 'Registrar'}
                </Button>
            </FormContainer>
            {showToast && <ToastComponent message={toastMessage} onClose={() => setShowToast(false)} />}
        </>
    );
};

export default LoginRegister;
