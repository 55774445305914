import React from 'react';
import { useHistory } from 'react-router-dom';
import './Modal.css'; // Arquivo de estilos do modal

const Modal = ({ isOpen, onClose, onConfirm, title, children, handleCopyPixCode }) => {
    // Mova o hook useHistory para o topo, antes de qualquer condição
    const history = useHistory();

    if (!isOpen) return null;

    const handleConfirm = () => {
        history.push('/pix');
    };

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h2 className="modal-title">{title}</h2>
                <div className="modal-content">
                    {children}
                </div>
                <div className="modal-actions">
                    <button className="modal-button cancel-button" onClick={onClose}>
                        Cancelar
                    </button>
                    <button className="modal-button confirm-button" onClick={handleConfirm}>
                        Confirmar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
