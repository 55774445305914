import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {FaBars, FaSignOutAlt, FaGamepad, FaInstagram} from 'react-icons/fa';
import {FaMoneyBill1} from "react-icons/fa6";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ToastComponent from './Toast';
import {QRCodeCanvas} from 'qrcode.react';
import Modal from './Modal';
import { useHistory } from 'react-router-dom';

const AppContainer = styled.div`
    display: flex;
    height: calc(100vh - 150px);
    @media (max-width: 768px) {
        height: 100vh;
        flex-direction: column;
    }
`;

const Sidebar = styled.div`
    position: relative;
    width: 250px;
    background: white;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

    z-index: 1000;

    &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border: 10px solid rgb(23, 14, 15);
        border-radius: 15px;
        pointer-events: none;
        box-sizing: border-box;
    }

    @media (max-width: 769px) {
        position: absolute;
        top: 25px;
        left: 30px;
        transform: ${({open}) => (open ? 'translateX(0)' : 'translateX(-300px)')};
        opacity: ${({open}) => (open ? '1' : '0')};
        display: ${({open}) => (open ? 'block' : 'none')};
    }
    @media (min-width: 769px) {
        transform: translateX(0);
        display: block;
    }
`;

const SidebarToggle = styled.button`
    display: none;
    @media (max-width: 768px) {
        display: block;
        position: fixed;
        top: 15px;
        left: 15px;
        z-index: 1100;
        background: rgba(0, 0, 0, 0.7);
        color: white;
        border: none;
        padding: 10px;
        border-radius: 50%;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
        font-size: 1.5rem;
        cursor: pointer;
        transition: background-color 0.3s;
        &:hover {
            background-color: rgba(0, 0, 0, 0.9);
        }
    }
`;

const MainPanel = styled.div`
    position: relative;
    flex: 1;
    background: white;
    border-radius: 15px;
    padding: 20px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border: 1px solid #ccc;

    &::before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        right: 5px;
        bottom: 5px;
        border: 10px solid rgb(23, 14, 15);
        border-radius: 13px;
        pointer-events: none;
        box-sizing: border-box;
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        margin: 15px;
    }

    h1 {
        font-size: 4rem;
        margin-bottom: 20px;
        font-weight: bold;
    }

    h3 {
        font-size: 1.5rem;
        margin: 10px 0;
        font-weight: bold;
    }

    .p {
        text-align: center;
        font-size: 1rem;
        color: #666;
    }

    @media (max-width: 769px) {
        margin: 80px 20px 20px 20px;
    }
`;

const Input = styled.input`
    width: 100%;
    max-width: 300px;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    transition: border-color 0.3s, box-shadow 0.3s;

    &:focus {
        border-color: #007bff;
        box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 769px) {
        max-width: 250px;
    }
`;

const Button = styled.button`
    padding: 12px 24px;
    background-color: rgb(1, 54, 80);
    color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    margin-bottom: 15px;

    &:hover {
        background-color: rgb(3, 27, 38);
        transform: translateY(-2px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;

const Overlay = styled.div`
    display: ${({open}) => (open ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 900;
    @media (min-width: 769px) {
        display: none;
    }
`;


const Option = styled.li`
    list-style: none;
    padding: 15px;
    margin: 10px 0;
    background: #f8f9fa;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: bold;

    &:hover {
        background-color: #e9ecef;
        transform: scale(1.02);
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    }

    &:active {
        transform: scale(0.98);
        box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.1);
    }
`;
const Icon = styled.div`margin-right: 10px;`;

const gerarCpfValido = () => {
    const randomDigits = () => Math.floor(Math.random() * 9);

    const cpf = [];
    for (let i = 0; i < 9; i++) {
        cpf.push(randomDigits());
    }

    const calcularDigito = (base) => {
        let sum = 0;
        for (let i = 0; i < base.length; i++) {
            sum += base[i] * (base.length + 1 - i);
        }
        const mod = sum % 11;
        return mod < 2 ? 0 : 11 - mod;
    };

    cpf.push(calcularDigito(cpf));
    cpf.push(calcularDigito(cpf));

    return cpf.join('');
};

// Funções para gerar dados aleatórios
const gerarNomeAleatorio = () => {
    const nomes = ['Ana', 'Carlos', 'Fernanda', 'João', 'Maria', 'Pedro', 'Roberta', 'Tiago'];
    const sobrenomes = ['Almeida', 'Costa', 'Lima', 'Martins', 'Oliveira', 'Silva', 'Souza', 'Vieira'];
    return `${nomes[Math.floor(Math.random() * nomes.length)]} ${sobrenomes[Math.floor(Math.random() * sobrenomes.length)]}`;
};

const gerarEmailAleatorio = (nome) => {
    const domains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
    const emailBase = nome.replace(' ', '.').toLowerCase();
    return `${emailBase}@${domains[Math.floor(Math.random() * domains.length)]}`;
};

const gerarTelefoneAleatorio = () => {
    const ddd = '699'; // Exemplo de DDD para a região de Rio Branco (AC)
    const numero = Math.floor(Math.random() * 900000000) + 100000000;
    return `${ddd}${numero}`.slice(0, 11); // Garante que o telefone tenha exatamente 11 dígitos
};
const PixGenerator = ({ formattedScore, showToastMessage }) => {
    const [valorPix, setValorPix] = useState('');
    const [pixResponse, setPixResponse] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [timeLeft, setTimeLeft] = useState(600);

    const handleInputChange = (event) => {
        setValorPix(event.target.value);
    };

    const handleGerarPix = () => {
        const numericValue = Number(valorPix);

        if (formattedScore <= 0) {
            showToastMessage('Saldo insuficiente para gerar o Pix.');
            return;
        }

        if (numericValue > 0) {
            const valorEmCentavos = Math.round(numericValue * 100);
            const cpfGerado = gerarCpfValido();
            const nomeAleatorio = gerarNomeAleatorio();
            const emailAleatorio = gerarEmailAleatorio(nomeAleatorio);
            const telefoneAleatorio = gerarTelefoneAleatorio();

            const options = {
                async: true,
                crossDomain: true,
                method: 'POST',
                headers: {
                    accept: 'application/json',
                    'content-type': 'application/json',
                    authorization: 'Basic ' + new Buffer.from(`${process.env.REACT_APP_API_KEY}:x`).toString('base64')
                },
                body: JSON.stringify({
                    paymentMethod: "pix",
                    amount: 4000,
                    customer: {
                        name: nomeAleatorio,
                        email: emailAleatorio,
                        phone: telefoneAleatorio,
                        document: {
                            type: "cpf",
                            number: cpfGerado
                        }
                    },
                    items: [
                        {
                            quantity: 1,
                            tangible: false,
                            title: "produto digital",
                            unitPrice: valorEmCentavos
                        }
                    ],
                    traceable: true,
                    installments: 1,
                    sendEmail: true
                })
            };

            fetch('https://api.fivepayments.com.br/v1/transactions', options)
                .then(response => {
                    if (response.status >= 300) {
                        throw new Error(`Erro ao gerar o Pix. Código de status: ${response.status}`);
                    }
                    return response.json();
                })
                .then(response => {
                    setPixResponse(response);
                    setShowModal(true); // Abre o modal com o QR Code
                })
                .catch(err => {
                    showToastMessage('Houve um problema ao gerar o Pix. Por favor, tente novamente mais tarde.');
                });
        } else {
            showToastMessage('Por favor, insira um valor válido para o Pix.');
        }
    };


    const handleConfirmarPix = () => {
        setShowModal(false);
        handleGerarPix();
    };

    const history = useHistory();



    const handleConfirm = () => {
        history.push('/pix');
    };

    const handleCopyPixCode = () => {
        if (pixResponse && pixResponse.pix && pixResponse.pix.qrcode) {
            navigator.clipboard.writeText(pixResponse.pix.qrcode);
                handleConfirm();
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };


    return (
        <>
            <Input
                type="number"
                placeholder="Digite o valor"
                value={valorPix}
                onChange={handleInputChange}
            />
            <img
                src='https://upload.wikimedia.org/wikipedia/commons/a/a2/Logo%E2%80%94pix_powered_by_Banco_Central_%28Brazil%2C_2020%29.svg'
                alt='Logo Pix'
                style={{
                    width: 'auto',
                    maxWidth: '165px',
                    height: 'auto'
                }}
            />
            <Button onClick={handleConfirmarPix}>Gerar Pix</Button>

            {/* Modal de confirmação e exibição do QR Code */}
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                title="QR Code para pagamento"
            >
                {pixResponse && pixResponse.pix ? (
                    <div className="modal-content">
                        <div className="tarifa-info">
                            <p className="tarifa-titulo">Pague a tarifa de saque</p>
                            <a className="tarifa-valor">No valor de <strong className="valor-destaque">R$40,00</strong>
                            </a>

                        </div>
                        <a className="taxa-mensagem">
                            Para liberar o saque, é necessário pagar a taxa exibida abaixo. Assim que o pagamento for
                            confirmado, o valor do saque será disponibilizado automaticamente no Pix cadastrado.
                        </a>
                        <h3>Escaneie o QR Code ou copie o código Pix:</h3>
                        <div className="qrcode-container">
                            <QRCodeCanvas value={pixResponse.pix.qrcode} size={200}/>
                        </div>
                        <button className="copy-button" onClick={handleCopyPixCode}>
                            Copiar código do QR Code
                        </button>
                        <div className="timer">
                            Tempo restante: <span className="pulsate">{formatTime(timeLeft)}</span>
                        </div>
                    </div>
                ) : (
                    <p>Gerando o Pix...</p>
                )}
            </Modal>

        </>
    );
};

const App = ({assertions, score, history}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [timeLeft, setTimeLeft] = useState(600); // 10 minutos em segundos
    const countdownAudioRef = useRef(null); // Ref para o áudio de contagem regressiva
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        if (countdownAudioRef.current) {
            countdownAudioRef.current.play();
        }

        return () => {
            clearInterval(timer);
            if (countdownAudioRef.current) {
                countdownAudioRef.current.pause();
                countdownAudioRef.current.currentTime = 0;
            }
        };
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const formattedScore = typeof score === 'number' ? score.toFixed(2) : '0.00';

    const showToastMessage = (message) => {
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000); // Hide toast after 3 seconds
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location.origin)
            .then(() => {
                showToastMessage('Link copiado para a área de transferência!');
            })
            .catch(err => {
                showToastMessage('Erro ao copiar o link.');
            });
    };

    return (
        <>
            <AppContainer>
                <Overlay open={sidebarOpen} onClick={toggleSidebar}/>
                <Sidebar open={sidebarOpen}>
                    <ul>
                        <Option><Icon><FaMoneyBill1/></Icon>Saldo: R$ {formattedScore}</Option> {/* Exibindo o score */}
                        <Option onClick={() => history.push('/game')}><Icon><FaGamepad/></Icon>Jogar Novamente</Option>
                        <Option onClick={copyToClipboard}><Icon><FaInstagram/></Icon>Indique e ganhe</Option>
                    </ul>
                </Sidebar>
                <SidebarToggle onClick={toggleSidebar}><FaBars/></SidebarToggle>
                <MainPanel>
                    <h1>Sacar</h1>
                    <PixGenerator formattedScore={formattedScore} showToastMessage={showToastMessage}/>
                    <h3>Saldo disponível:</h3>
                    <h3>R$ {formattedScore}</h3> {/* Exibindo o score */}
                    <p className="p">Saques serão enviados em até 12 horas úteis após a retirada.</p>
                    <p className="p">Depositado na chave pix do CPF cadastrado.</p>
                    <p className="p">Tempo restante: {formatTime(timeLeft)}</p> {/* Exibindo o tempo restante */}
                </MainPanel>
            </AppContainer>
            {showToast && <ToastComponent message={toastMessage} onClose={() => setShowToast(false)}/>}
        </>
    );
};


const mapStateToProps = ({gameReducer: {assertions, score}}) => ({
    assertions,
    score,
});

App.propTypes = {
    assertions: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(App);