import React, { useState } from 'react';
import LoginRegister from '../components/Login'; // Certifique-se de que o caminho esteja correto
import LoginForm from '../components/LoginForm';
import style from './Home.module.css';

const Home = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        // Opcional: redirecionar para a página de login, se necessário
        // history.push('/login'); // Descomente se usar react-router
    };

    return (
        <main className={style.container}>
            {isAuthenticated && (
                <button className={style.logoutButton} onClick={handleLogout}>
                    Deslogar
                </button>
            )}
            {!isAuthenticated ? (
                <LoginRegister onLoginSuccess={handleLoginSuccess} />
            ) : (
                <LoginForm />
            )}
        </main>
    );
};

export default Home;
