import React, { useState, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { FaTimes } from 'react-icons/fa'; // Certifique-se de que o pacote react-icons está instalado

const slideIn = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideOut = keyframes`
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
`;

const ToastContainer = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    max-width: 350px;
    width: 100%;
    z-index: 1000;
    pointer-events: none; /* Ensures clicks do not interfere with other elements */
`;

const Toast = styled.div`
    background: linear-gradient(135deg, #0098C1, #00E6A6); /* Gradiente vibrante */
    color: #fff;
    padding: 15px;
    border-radius: 12px;
    margin-bottom: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: ${({ isExiting }) => isExiting ? css`${slideOut} 0.5s ease-out` : css`${slideIn} 0.5s ease-out`};
    transition: opacity 0.3s ease;

    &:last-child {
        margin-bottom: 0;
    }
`;

const ToastMessage = styled.span`
    flex: 1;
    padding-right: 15px;
    font-weight: 500; /* Letras mais destacadas */
`;

const CloseButton = styled.button`
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: color 0.3s, transform 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.2); /* Fundo leve ao passar o mouse */
        transform: scale(1.1);
    }

    &:focus {
        outline: none;
    }
`;

const ToastComponent = ({ message, onClose, duration = 3000 }) => {
    const [isExiting, setIsExiting] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsExiting(true);
            setTimeout(onClose, 500); // Delay to allow for exit animation
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    return (
        <ToastContainer>
            <Toast isExiting={isExiting}>
                <ToastMessage>{message}</ToastMessage>
                <CloseButton onClick={() => { setIsExiting(true); setTimeout(onClose, 500); }}>
                    <FaTimes />
                </CloseButton>
            </Toast>
        </ToastContainer>
    );
};

export default ToastComponent;
