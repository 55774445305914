import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';

const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  font-family: 'Arial', sans-serif;
`;

const ConfirmationContent = styled.div`
  text-align: center;
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
`;

const ConfirmationIcon = styled(FaCheckCircle)`
  font-size: 60px;
  color: #28a745;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
`;

const Message = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
`;

const Button = styled.button`
    padding: 12px 24px;
    background-color: rgb(1, 54, 80);
    color: white;
    border: none;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
    margin-bottom: 15px;

    &:hover {
        background-color: rgb(3, 27, 38);
        transform: translateY(-2px);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
`;


const ConfirmationScreen = () => {
    const history = useHistory();
    const handleConfirm = () => {
        history.push('/game');
    };
    return (
        <ConfirmationContainer>
            <ConfirmationContent>
                <ConfirmationIcon />
                <Title>Confirmação Pendente!</Title>
                <Message>O comprovante de confirmação do seu PIX será enviado para o e-mail cadastrado após o pagamento da taxa de R$ 40,00.</Message>
                <Message>Verifique sua caixa de entrada e também a pasta de spam após a confirmação do pagamento.</Message>
                <Button onClick={handleConfirm}>Jogar Novamente</Button>
            </ConfirmationContent>
        </ConfirmationContainer>

    );
};

export default ConfirmationScreen;
